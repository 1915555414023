export interface UiState {
  isCartSidebarOpen: boolean
  isFilterSidebarOpen: boolean
  isLocaleSidebarOpen: boolean
  isProductReviewsSidebarOpen: boolean
  isWishlistSidebarOpen: boolean
  isSizeChartSidebarOpen: boolean
  isLoginModalOpen: boolean
  isNewsletterModalOpen: boolean
  isCategoryGridView: boolean
  isWishlistGridView: boolean
  isMobileMenuOpen: boolean
  v125019MobileSearchMenuFocussed: boolean
  isProductLightboxOpen: boolean
  isHeaderNotificationVisible: boolean
  isTopHeaderVisible: boolean
  soldOutForm: {
    productId?: number | null
    variantId?: number | null
    optionLabel?: string | null
    type?: string | null
    isModalVisible?: boolean
  }
  itemAddedToCart: {
    productSku?: number | null
    variantId?: string | null
    isModalVisible?: boolean
  }
  currentSlideIndex?: number | null
}

const useUiState = defineStore('uiState', () => {
  const state: UiState = reactive({
    isCartSidebarOpen: false,
    isFilterSidebarOpen: false,
    isLocaleSidebarOpen: false,
    isProductReviewsSidebarOpen: false,
    isWishlistSidebarOpen: false,
    isSizeChartSidebarOpen: false,
    isLoginModalOpen: false,
    isNewsletterModalOpen: false,
    isCategoryGridView: true,
    isWishlistGridView: false,
    isMobileMenuOpen: false,
    v125019MobileSearchMenuFocussed: false,
    isHeaderNotificationVisible: false,
    isProductLightboxOpen: false,
    isTopHeaderVisible: true,
    soldOutForm: {
      productId: null,
      variantId: null,
      optionLabel: null,
      type: null,
      isModalVisible: false,
    },
    itemAddedToCart: {
      productSku: null,
      variantId: null,
      isModalVisible: false,
    },
    currentSlideIndex: 0,
  })

  const isMobileMenuOpen = computed(() => state.isMobileMenuOpen)
  const v125019MobileSearchMenuFocussed = computed({
    get: () => state.v125019MobileSearchMenuFocussed,
    set: (shouldFocus) => (state.v125019MobileSearchMenuFocussed = shouldFocus),
  })
  const toggleMobileMenu = (ab25019SearchFocussed: boolean = false) => {
    state.v125019MobileSearchMenuFocussed = ab25019SearchFocussed
    state.isMobileMenuOpen = !state.isMobileMenuOpen
    document.documentElement.classList[isMobileMenuOpen.value ? 'add' : 'remove']('no-scroll')
  }

  const isCartSidebarOpen = computed(() => state.isCartSidebarOpen)
  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu()
    state.isCartSidebarOpen = !state.isCartSidebarOpen
  }

  const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen)
  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu()
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen
  }

  const isLoginModalOpen = computed(() => state.isLoginModalOpen)
  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu()
    state.isLoginModalOpen = !state.isLoginModalOpen
  }

  const isNewsletterModalOpen = computed(() => state.isNewsletterModalOpen)
  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen
  }

  const isCategoryGridView = computed(() => state.isCategoryGridView)

  const isWishlistGridView = computed(() => state.isWishlistGridView)

  const isHeaderNotificationVisible = computed(() => state.isHeaderNotificationVisible)
  const showHeaderNotification = () => {
    state.isHeaderNotificationVisible = true
  }
  const hideHeaderNotification = () => {
    state.isHeaderNotificationVisible = false
  }

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true
  }
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false
  }

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true
  }

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false
  }

  const isFilterSidebarOpen = computed(() => state.isFilterSidebarOpen)
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen
  }
  const closeFilterSidebar = () => {
    state.isFilterSidebarOpen = false
  }

  const isLocaleSidebarOpen = computed(() => state.isLocaleSidebarOpen)
  const toggleLocaleSidebar = () => {
    state.isLocaleSidebarOpen = !state.isLocaleSidebarOpen
  }

  const isSizeChartSidebarOpen = computed(() => state.isSizeChartSidebarOpen)
  const toggleIsSizeChartSidebarOpen = () => {
    state.isSizeChartSidebarOpen = !state.isSizeChartSidebarOpen
  }

  const isProductReviewsSidebarOpen = computed(() => state.isProductReviewsSidebarOpen)
  const toggleProductReviewsSidebar = () => {
    state.isProductReviewsSidebarOpen = !state.isProductReviewsSidebarOpen
  }

  const isProductLightboxOpen = computed(() => state.isProductLightboxOpen)
  const toggleProductLightBox = () => {
    state.isProductLightboxOpen = !state.isProductLightboxOpen
  }

  const closeProductLightBox = () => {
    state.isProductLightboxOpen = false
  }

  const isTopHeaderVisible = computed(() => state.isTopHeaderVisible)
  const toggleTopHeader = (value: boolean) => {
    if (value !== undefined) return (state.isTopHeaderVisible = value)
    state.isTopHeaderVisible = !state.isTopHeaderVisible
  }

  const soldOutForm = computed(() => state.soldOutForm)
  const setSoldOutFormData = (params: {
    productId?: number
    variantId?: number | null
    optionLabel?: string | null
    type?: 'soldOut' | 'comingSoon'
    isModalVisible?: boolean
  }) => {
    state.soldOutForm = {
      ...state.soldOutForm,
      ...params,
    }
  }

  const itemAddedToCart = computed(() => state.itemAddedToCart)
  const setItemAddedToCart = (params: {
    productSku?: number | null
    variantId?: string | null
    isModalVisible?: boolean
  }) => {
    state.itemAddedToCart = {
      ...state.itemAddedToCart,
      ...params,
    }

    if (params.isModalVisible) {
      setTimeout(() => {
        state.itemAddedToCart.isModalVisible = false
      }, 5000)
    }
  }

  const currentSlideIndex = computed({
    get() {
      return state.currentSlideIndex
    },
    set(value: number) {
      state.currentSlideIndex = value
    },
  })

  const isMobile = computed(() => window?.matchMedia(`(max-width: 768px)`).matches ?? false)

  const closeAllSidebars = () => {
    state.isCartSidebarOpen = false
    state.isFilterSidebarOpen = false
    state.isLocaleSidebarOpen = false
    state.isProductReviewsSidebarOpen = false
    state.isWishlistSidebarOpen = false
    state.isMobileMenuOpen = false
    state.v125019MobileSearchMenuFocussed = false
    state.isSizeChartSidebarOpen = false
  }

  return {
    isLoginModalOpen,
    isNewsletterModalOpen,
    isCategoryGridView,
    isWishlistGridView,
    isMobileMenuOpen,
    v125019MobileSearchMenuFocussed,
    isHeaderNotificationVisible,
    isCartSidebarOpen,
    isFilterSidebarOpen,
    isLocaleSidebarOpen,
    isProductReviewsSidebarOpen,
    isWishlistSidebarOpen,
    isSizeChartSidebarOpen,
    toggleIsSizeChartSidebarOpen,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleLoginModal,
    toggleNewsletterModal,
    toggleCartSidebar,
    toggleFilterSidebar,
    closeFilterSidebar,
    toggleLocaleSidebar,
    toggleProductReviewsSidebar,
    toggleWishlistSidebar,
    toggleMobileMenu,
    showHeaderNotification,
    hideHeaderNotification,
    closeAllSidebars,
    soldOutForm,
    setSoldOutFormData,
    itemAddedToCart,
    setItemAddedToCart,
    isTopHeaderVisible,
    toggleTopHeader,
    isProductLightboxOpen,
    toggleProductLightBox,
    closeProductLightBox,
    currentSlideIndex,
    isMobile,
  }
})

export default useUiState
