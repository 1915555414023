import type { AppConfigInput } from '@nuxt/schema'

export default {
  checkbox: {
    slots: {
      base: 'md:h-[1.5rem] md:w-[1.5rem]',
      icon: 'h-3 w-3 md:h-4 md:w-4',
    },
  },
} as AppConfigInput['ui']
