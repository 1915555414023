import type { AppConfigInput } from '@nuxt/schema'

export default {
  modal: {
    slots: {
      overlay: 'bg-[var(--ui-bg-inverted)]/15',
      content: 'divide-none ring-0!',
    },
    variants: {
      fullScreen: {
        false: {
          content: 'p-6 rounded-none! ring-0!',
        },
      },
    },
  },
} as AppConfigInput['ui']
