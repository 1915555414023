import type { AppConfigInput } from '@nuxt/schema'

export default {
  alert: {
    slots: {
      description: 'text-md opacity-90',
    },
    compoundVariants: [
      {
        color: 'error',
        variant: 'solid',
        class: {
          root: 'bg-(--red-200) text-(--black-primary)',
        },
      },
      {
        color: 'success',
        variant: 'solid',
        class: {
          root: 'bg-(--green-100) text-(--black-primary)',
        },
      },
    ],
  },
} as AppConfigInput['ui']
