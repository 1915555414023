import type { AppConfigInput } from '@nuxt/schema'

export default {
  drawer: {
    slots: {
      overlay: 'bg-[var(--ui-bg-inverted)]/15',
      container: 'pb-8',
      content: 'ring-0 shadow-xl shadow-neutral-950',
      title: 'mb-0',
    },
    compoundVariants: [
      {
        direction: ['top', 'bottom'],
        class: {
          handle: 'w-7 h-[2px] mt-2',
          content: 'rounded-t-xl'
        },
      },
    ],
  },
} as AppConfigInput['ui']
