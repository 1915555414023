import generateCustomerToken from './generateCustomerToken.gql'
import createCustomerV2 from './createCustomerV2'
import requestPasswordResetEmail from './requestPasswordResetEmail'
import changeCustomerPassword from './changeCustomerPassword'
import revokeCustomerToken from './revokeCustomerToken'
import MJresetPassword from './MJresetPassword'
import customerCart from './customerCart'
import mergeCarts from './mergeCarts'
import updateCustomerEmail from './updateCustomerEmail'
import updateCustomer from './updateCustomer'
import customer from './customer'

const apiMethods = {
  post: {
    generateCustomerToken,
    createCustomerV2,
    requestPasswordResetEmail,
    changeCustomerPassword,
    revokeCustomerToken,
    MJresetPassword,
    mergeCarts,
    updateCustomerEmail,
    updateCustomer,
  },
  get: {
    customer,
    customerCart,
  },
}

export type PostApiMethods = keyof typeof apiMethods.post
export type GetApiMethods = keyof typeof apiMethods.get

export default apiMethods
