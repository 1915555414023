export default `
  mutation updateCustomerEmail(
    $email: String!
    $password: String!
  ) {
    updateCustomerEmail(
      email: $email
      password: $password
    ) {
      customer {
        id
        email
      }
    }
  }
`
