/**
 * Adds the filters to the URL. I.e: /bestsellers/blau-gold.html?sizes=xl
 * Retains existing query strings (like q, p, etc.)
 * @returns void
 */
import { doSearchOperations, facetIds, nonPrettyUrlFacets } from '../useBloomreachDiscoveryStore'
import type { RouteLocationRaw } from '#vue-router'

const getFilters = (filters, prettyUrls, facets) => {
  const logger = useAppLogger('setFilterPath')
  const { routeData } = usePageStore()

  const selectedPrettyUrls: string[] = []
  const prettyUrlFiltersToStrings: string[] = []
  const queryStringFilters = {}

  const handlePrettyUrl = (key: string, value: string) => {
    selectedPrettyUrls.push(value)
    const prettyUrl = prettyUrls.value?.find((prettyUrl) => prettyUrl.label?.replaceAll('-', '') === value.replaceAll('-', ''))
    const isFacet = !!facets.value?.find((facet) => facet.id === prettyUrl?.type)

    if (!isFacet) {
      logger.warn(`Found pretty URL "${value}", but it doesn't have a corresponding facet type`)
    }

    if (prettyUrl?.slug && isFacet) {
      prettyUrlFiltersToStrings.push(prettyUrl.slug)
    } else {
      queryStringFilters[key] = queryStringFilters[key] ? [...queryStringFilters[key], value] : [value]
    }
  }

  // Split filters into pretty url filters and query string filters
  for (const [key, value] of Object.entries(filters)) {
    if (!value || (Array.isArray(value) && value.length < 1)) continue
    if (nonPrettyUrlFacets.includes(key) || routeData?.type !== 'CATEGORY') {
      queryStringFilters[key] = Array.isArray(value) ? value : [value]
    } else if (Array.isArray(value)) {
      value.forEach((val) => {
        handlePrettyUrl(key, val)
      })
    } else {
      handlePrettyUrl(key, value)
    }
  }

  return {
    selectedPrettyUrls,
    prettyUrlFiltersToStrings,
    queryStringFilters,
  }
}

export default async (moreProducts: string | null = null) => {
  const config = useRuntimeConfig()
  const { routeData } = usePageStore()
  const localePath = useLocalePath()
  const {
    prependedPage,
    currentPage,
    selectedFilters,
    selectedSortOption,
    appliedFilters,
    selectedPrettyUrls,
    facets,
  } = storeToRefs(useBloomreachDiscoveryStore())
  const { prettyUrls } = storeToRefs(usePrettyUrlStore())

  const page = moreProducts === doSearchOperations.PREPEND ? prependedPage.value : currentPage.value

  const filters: Record<string, string> = JSON.parse(JSON.stringify(selectedFilters.value)) // Remove reactivity

  const {
    queryStringFilters,
    prettyUrlFiltersToStrings,
    selectedPrettyUrls: selectedPrettyUrlsResult,
  } = getFilters(filters, prettyUrls, facets)

  selectedPrettyUrls.value = selectedPrettyUrlsResult

  let relativeUrl = routeData?.relative_url

  const urlString = prettyUrlFiltersToStrings.length
    ? localePath('/' + relativeUrl.replace('.html', `/${prettyUrlFiltersToStrings.join('-')}.html`))
    : localePath(`/${relativeUrl}`)

  const url = new URL(config.public.storeUrl + urlString)

  // Retain existing query strings, except for the facet filters
  const currentSearchParams = new URLSearchParams(window.location.search)
  currentSearchParams.forEach((value, key) => {
    if (facetIds.includes(key)) return url.searchParams.delete(key)
    url.searchParams.append(key, value)
  })

  // Add the facet filters to the query string
  Object.keys(queryStringFilters).forEach((key) => {
    if (!queryStringFilters[key]?.length) return
    url.searchParams.append(key, queryStringFilters[key])
  })

  // Add sort option to query string
  url.searchParams.delete('sort')
  if (selectedSortOption.value) {
    url.searchParams.append('sort', selectedSortOption.value)
  }

  // Pagination
  if (JSON.stringify(appliedFilters.value) !== JSON.stringify(selectedFilters.value)) {
    currentPage.value = 1
  }

  url.searchParams.delete('p')
  if (page > 1) {
    url.searchParams.append('p', page.toString())
  }

  const navigationDetails: RouteLocationRaw = {
    path: url.pathname,
    query: Object.fromEntries(url.searchParams.entries()),
    force: true,
  }

  await navigateTo(navigationDetails)
}
