import customerFragment from '~/server/utils/magento-queries/customer.fragment'

export default `
  mutation UpdateCustomerV2($firstname: String, $lastname: String, $date_of_birth: String) {
    updateCustomerV2(input: {
      firstname: $firstname, 
      lastname: $lastname, 
      date_of_birth: $date_of_birth
    }) {
        customer {
            ${customerFragment}
        }
    }
}
`
