import type { AppConfigInput } from '@nuxt/schema'

export default {
  button: {
    slots: {
      base: 'rounded-xs p-4 font-medium transition-colors w-full flex justify-center md:inline-flex md:w-auto md:self-start hover:cursor-pointer',
      trailingIcon: 'ml-auto',
    },
    variants: {
      size: {
        xs: {
          base: 'px-2 py-1 text-xs gap-1',
          leadingIcon: 'size-4',
          leadingAvatarSize: '3xs',
          trailingIcon: 'size-4',
        },
        sm: {
          base: 'px-2.5 py-1.5 text-xs gap-1.5',
          leadingIcon: 'size-4',
          leadingAvatarSize: '3xs',
          trailingIcon: 'size-4',
        },
        md: {
          base: 'px-3 py-[12px] text-md gap-1.5 md:py-4 md:px-8',
          leadingIcon: 'size-5',
          leadingAvatarSize: '2xs',
          trailingIcon: 'size-5',
        },
        lg: {
          base: 'px-3 py-[12px] text-md gap-2',
          leadingIcon: 'size-5',
          leadingAvatarSize: '2xs',
          trailingIcon: 'size-5',
        },
        xl: {
          base: 'px-3 py-2.5 text-base gap-2',
          leadingIcon: 'size-6',
          leadingAvatarSize: 'xs',
          trailingIcon: 'size-6',
        },
      },
    },
    compoundVariants: [
      {
        color: 'secondary',
        variant: 'solid',
        class: 'hover:shadow-md hover:bg-(--black-primary)',
      },
      {
        color: 'secondary',
        variant: 'outline',
        class: 'hover:shadow-md hover:bg-white ring-(--ui-secondary)',
      },
      {
        color: 'neutral',
        variant: 'solid',
        class: 'bg-(--grey-background) text-(--black-primary) hover:shadow-md hover:bg-(--grey-300)',
      },
    ],
  },
} as AppConfigInput['ui']
