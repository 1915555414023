import type { Cart, CartItemInput } from '@vue-storefront/magento-types'
import { cartCookieName } from '~/enums/cookieNameEnum'
import type { Product } from '~/types/product'

export declare type AddProductsToCartInput = {
  cartId: string
  cartItems: CartItemInput[]
}

type ExecuteParams = {
  product: Product
  quantity: number
  currentCart: Cart
  productConfiguration: Record<string, any>
  productCustomOptionsConfiguration: Record<string, any>
}

export const addItemCommand = {
  execute: async ({
    product,
    quantity,
    currentCart,
    productConfiguration,
    productCustomOptionsConfiguration,
  }: ExecuteParams) => {
    console.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    })
    const { $sdk } = useNuxtApp()
    const { setCart } = useCartStore()
    const cartCookie = useCookie(cartCookieName)

    const cartId = <string>(cartCookie.value?.indexOf('|') ? cartCookie.value?.split('|').pop() : cartCookie.value)

    if (!product) return

    const cartInput: AddProductsToCartInput = {
      cartId: cartId,
      cartItems: [
        {
          quantity,
          sku: product.sku,
        },
      ],
    }

    if (Object.entries(productCustomOptionsConfiguration || {}).length) {
      cartInput.cartItems[0].entered_options = Object.entries(productCustomOptionsConfiguration).map(
        ([uid, value]) => ({
          uid,
          value: value.toString(),
        }),
      )
    }

    if (product.bundle_options) {
      cartInput.cartItems[0].entered_options = product.bundle_options.map((bundleOption) => ({
        ...bundleOption,
        value: bundleOption.value.toString(),
      }))
    }

    if (Object.values(productConfiguration || {}).length) {
      const selectedVariant = product.variants.find((variant) =>
        Object.values(productConfiguration).every((option) =>
          variant.attributes?.find((attribute) => attribute?.uid === option),
        ),
      )

      if (!selectedVariant) return console.warn('No variant found for product ' + product.sku)

      cartInput.cartItems[0].selected_options = Object.values(productConfiguration)
    }

    let { data, errors } = await $sdk.magento.addProductsToCart(cartInput, {
      addProductsToCart: 'add-products-to-cart',
    })

    if (errors?.length) throw errors

    setCart(data?.addProductsToCart?.cart as Cart)

    console.debug('[Result]:', { data })

    return data!.addProductsToCart?.cart as Cart
  },
}
