import type { Breadcrumb } from '~/types/breadcrumbs'
import type { CategoryInterface } from '@vue-storefront/magento-types'
import type { CategoryList } from '~/stores/useCategoryStore/categoryStore'
import { useRoute } from 'vue-router'
import { getPages } from './commands/getPages'
import type { Locale } from '~/types/locale'

export const index = defineStore('breadcrumbs', () => {
  const { $i18n, $localePath } = useNuxtApp()
  const { routeData } = storeToRefs(usePageStore())
  const route = useRoute()

  const state = reactive({
    breadcrumbs: <Breadcrumb[]>[],
    previousPcpBreadcrumbs: <Breadcrumb[]>[],
    loading: <boolean>false,
  })

  const getFullPaths = () => {
    const currentPath =
      route.fullPath
        .replace('/' + $i18n.locale.value, '')
        .split('?')
        .shift() || ''
    const paths = currentPath
      .replace('_index_', '')
      .split('/')
      .filter((p) => p !== '_index_')
      .filter((path) => path?.length)
    return paths.map((_slug, index) => paths.slice(0, index + 1).join('/'))
  }

  const unslugify = (slug: string) => {
    const result = slug.replace(/\-/g, ' ')
    return result.replace(/\w\S*/g, (s) => {
      return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase().replace('.html', '')
    })
  }

  const fetchPagePath = async (path: string) => {
    const storedItem = state.breadcrumbs.find((breadcrumb) => breadcrumb.link === path)
    if (storedItem) return storedItem

    return getPages
      .execute({
        query: {
          path: path,
          locale: $i18n.locale.value as Locale,
        },
      })
      .then((res) => {
        const item = {
          success: true,
          link: $localePath(`/${path}`),
          text: res.page[res.document.$ref.substring(6)].data.displayName,
        }

        state.breadcrumbs.push(item)
      })
      .catch((e) => {
        const lastSlug = path.split('/').pop()
        const item = {
          success: false,
          link: '',
          text: unslugify(lastSlug as string),
        }

        state.breadcrumbs.push(item)
      })
  }

  const generatePDPBreadcrumbs = (productCategories: CategoryInterface[]) => {
    state.loading = true
    const breadcrumbCategory: CategoryInterface = productCategories
      ?.sort((a, b) => (b.breadcrumbs?.length || 0) - (a.breadcrumbs?.length || 0))
      ?.shift() || { breadcrumbs: [], url_suffix: '', uid: '' }

    // If the previous page is a category, we want to show the category breadcrumbs from where the user came from
    if (state.previousPcpBreadcrumbs.length) {
      state.breadcrumbs = state.previousPcpBreadcrumbs
    } else {
      if (!breadcrumbCategory?.breadcrumbs?.length) {
        return [{ text: '', link: $localePath(`/`) }]
      }

      if (Array.isArray(breadcrumbCategory.breadcrumbs)) {
        state.breadcrumbs = []
        state.breadcrumbs.push({
          link: $localePath('/') + '/',
          text: 'Home',
          isHome: true,
        })
        state.breadcrumbs.push(
          ...breadcrumbCategory.breadcrumbs.map(
            (breadcrumb): Breadcrumb => ({
              text: breadcrumb?.category_name,
              link: $localePath(`/${breadcrumb?.category_url_path}${breadcrumbCategory.url_suffix || ''}`),
              id: breadcrumb?.category_id,
            }),
          ),
        )
      }
      state.breadcrumbs.push({
        text: breadcrumbCategory.name,
        link: $localePath(`/${breadcrumbCategory.url_path}${breadcrumbCategory.url_suffix || ''}`),
        id: breadcrumbCategory.id,
      })
    }

    state.loading = false
  }

  const generatePCPBreadcrumbs = (categoryList: CategoryList, queryCategoryId: number) => {
    const currentCategory =
      categoryList?.find((category) => parseInt(category.id) === parseInt(routeData.value.id)) || categoryList?.[0]

    if (currentCategory?.breadcrumbs?.length > 0) {
      state.breadcrumbs = []
      state.breadcrumbs.push({
        link: $localePath('/') + '/',
        text: 'Home',
        isHome: true,
      })
      state.breadcrumbs.push(
        ...currentCategory!.breadcrumbs!.map((item) => {
          return {
            link: $localePath(`/${item.category_url_path}.html`),
            text: item.category_name,
            id: item.category_id.toString(),
          }
        }),
      )
      //If dealing with the parent's category list
      if (queryCategoryId != routeData.value.id) {
        state.breadcrumbs.push({
          link: $localePath('/' + routeData.value.relative_url),
          text: currentCategory?.name,
          id: currentCategory?.id,
        })
      }
    }
  }

  const generateSearchBreadcrumbs = () => {
    const route = useRoute()
    const q = route.query?.q?.toString().replace('__empty__', '') || routeData.value.query.q || ''

    state.breadcrumbs = [
      {
        link: $localePath('/') + '/',
        text: 'Home',
        isHome: true,
      },
      {
        link: $localePath(`/catalogsearch/result?q=${q}`),
        text: $i18n.t('Search results for: “{keyword}”', { keyword: q }),
      },
    ]
  }

  const autoGenerateBreadcrumbs = async (isMobile: boolean) => {
    state.loading = true

    state.breadcrumbs = [
      {
        link: $localePath('/') + '/',
        text: 'Home',
        isHome: true,
      },
    ]

    const fullPaths = getFullPaths()
    if (isMobile) {
      await fetchPagePath(fullPaths[0])
      state.loading = false
      return
    }

    for (const path of fullPaths) {
      await fetchPagePath(path)
    }

    state.loading = false
  }

  /**
   * @description
   * Auto generate breadcrumbs based on current route
   *
   * Login pages only show "home"
   *
   * Account pages only show "account overview"
   *
   * @returns void
   */
  const generateGeneralBreadcrumbs = () => {
    const route = useRoute()
    const fullPath = route.fullPath.replace('/' + $i18n.locale.value, '')
    const paths = fullPath.split('/')

    const loginFlowPaths = [
      '/customer/account/login',
      '/customer/account/create',
      '/customer/account/forgotpassword',
      '/customer/account/createpassword',
    ]
    const isLoginFlow = loginFlowPaths.some((path) => fullPath.includes(path))

    const accountPagePaths = ['/customer', '/sales', '/wishlist']
    const isAccountPage = accountPagePaths.some((path) => fullPath.includes(path)) && !isLoginFlow

    if (isLoginFlow) {
      state.breadcrumbs = [
        {
          link: $localePath('/'),
          text: 'Home',
          isHome: true,
        },
      ]
    } else if (isAccountPage) {
      state.breadcrumbs = [
        {
          link: $localePath('/customer/account'),
          text: 'Account overview',
        },
      ]
    } else {
      state.breadcrumbs = paths.map((path) => {
        return {
          link: $localePath(`/${path}`),
          text: unslugify(path),
        }
      })
    }
  }

  return {
    ...toRefs(state),
    generatePDPBreadcrumbs,
    generatePCPBreadcrumbs,
    generateSearchBreadcrumbs,
    autoGenerateBreadcrumbs,
    generateGeneralBreadcrumbs,
  }
})

export default index
