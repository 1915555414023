import type { CustomerCreateInput } from '@vue-storefront/magento-types'

export const generateUserData = (userData: any): CustomerCreateInput => {
  const baseData = {
    email: userData.email,
    firstname: userData.firstName || userData.firstname,
    lastname: userData.lastName || userData.lastname,
  } as CustomerCreateInput

  if (Object.hasOwn(userData, 'is_subscribed')) {
    baseData.is_subscribed = userData.is_subscribed
  }

  if (
    Object.hasOwn(userData, 'dateOfBirth') ||
    Object.hasOwn(userData, 'date_of_birth')
  ) {
    baseData.date_of_birth = userData.dateOfBirth || userData.date_of_birth
  }

  if (Object.hasOwn(userData, 'gender')) {
    baseData.gender = userData.gender
  }

  if (Object.hasOwn(userData, 'taxvat')) {
    baseData.taxvat = userData.taxvat
  }

  if (Object.hasOwn(userData, 'prefix')) {
    baseData.prefix = userData.prefix
  }

  if (Object.hasOwn(userData, 'suffix')) {
    baseData.suffix = userData.suffix
  }

  if (Object.hasOwn(userData, 'password')) {
    baseData.password = userData.password
  }

  if (Object.hasOwn(userData, 'recaptchaToken')) {
    baseData.recaptchaToken = userData.recaptchaToken
  }

  return baseData
}
