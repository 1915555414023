import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/my-jewellery/code/frontend-nuxt3/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_NBeUjxfnqeHAnJnJfx2qPiCTldh7mjF3nAnlbt5cM7Y from "/builds/my-jewellery/code/frontend-nuxt3/plugins/base64.ts";
import brxm_7j4gqM5Y5bnJxV_rvK6_Rwv8Kj803RRaZ9XxCtQ580I from "/builds/my-jewellery/code/frontend-nuxt3/plugins/brxm.ts";
import datadog_rum_client_BZgs0mjlFc4BJ0o0uye2ZFFwiVSC2FIjd_9caq_Ni3c from "/builds/my-jewellery/code/frontend-nuxt3/plugins/datadog-rum.client.ts";
import exponeaHelpers_client_AWTZkV7knIdI0YdNgHiE5YPpDdaYeOdIJCGiuKa5oLY from "/builds/my-jewellery/code/frontend-nuxt3/plugins/exponeaHelpers.client.ts";
import formatCurrency_zYhBFAT9xDifd2NSc4_ZmSAXctlHZwShHFliUbMRUAU from "/builds/my-jewellery/code/frontend-nuxt3/plugins/formatCurrency.ts";
import lazy_hydration_hhF7e37ZJEuSQHE_r5fQG7C_dfP0asRMsd8ofi9JmeA from "/builds/my-jewellery/code/frontend-nuxt3/plugins/lazy-hydration.ts";
import magento_api_6caZdFhw49SiW4Iao_H23s5Ssm9_bJRC6Uawuay0aNs from "/builds/my-jewellery/code/frontend-nuxt3/plugins/magento-api.ts";
import purifyHtml_bwPqqErtY4hYNhFbUXUlkLXUJLQBnt1LWV0CakFMwCA from "/builds/my-jewellery/code/frontend-nuxt3/plugins/purifyHtml.ts";
import recaptcha_vq_SqlhdLs4FUNHaAfRS_nsTXv2Kinr4Q_VI_3s7mjg from "/builds/my-jewellery/code/frontend-nuxt3/plugins/recaptcha.ts";
import sdk_bMcCSRrjcBJx1svxu3stUtF2BpKsrRhVkUgKXw_jMtg from "/builds/my-jewellery/code/frontend-nuxt3/plugins/sdk.ts";
import trustedShop_client_9r4NlI0gbvUgScur8rR72w4kQ8lFQxd_BDhT2_sRF0U from "/builds/my-jewellery/code/frontend-nuxt3/plugins/trustedShop.client.ts";
import veeValidate_JcDSupqCJgR_YXJ6S7_DGq4DtRIf0nzILuAct4UeJuA from "/builds/my-jewellery/code/frontend-nuxt3/plugins/veeValidate.ts";
import z_initialization_YzR8bAaap1l32UM39bh002Htsoqw3UIn8OD_R5Kpjm0 from "/builds/my-jewellery/code/frontend-nuxt3/plugins/z.initialization.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_7DB4Q_rSg7Y6_r2xTJAR9sj0Plzl7GeeI8C3uqDzeqY,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  base64_NBeUjxfnqeHAnJnJfx2qPiCTldh7mjF3nAnlbt5cM7Y,
  brxm_7j4gqM5Y5bnJxV_rvK6_Rwv8Kj803RRaZ9XxCtQ580I,
  datadog_rum_client_BZgs0mjlFc4BJ0o0uye2ZFFwiVSC2FIjd_9caq_Ni3c,
  exponeaHelpers_client_AWTZkV7knIdI0YdNgHiE5YPpDdaYeOdIJCGiuKa5oLY,
  formatCurrency_zYhBFAT9xDifd2NSc4_ZmSAXctlHZwShHFliUbMRUAU,
  lazy_hydration_hhF7e37ZJEuSQHE_r5fQG7C_dfP0asRMsd8ofi9JmeA,
  magento_api_6caZdFhw49SiW4Iao_H23s5Ssm9_bJRC6Uawuay0aNs,
  purifyHtml_bwPqqErtY4hYNhFbUXUlkLXUJLQBnt1LWV0CakFMwCA,
  recaptcha_vq_SqlhdLs4FUNHaAfRS_nsTXv2Kinr4Q_VI_3s7mjg,
  sdk_bMcCSRrjcBJx1svxu3stUtF2BpKsrRhVkUgKXw_jMtg,
  trustedShop_client_9r4NlI0gbvUgScur8rR72w4kQ8lFQxd_BDhT2_sRF0U,
  veeValidate_JcDSupqCJgR_YXJ6S7_DGq4DtRIf0nzILuAct4UeJuA,
  z_initialization_YzR8bAaap1l32UM39bh002Htsoqw3UIn8OD_R5Kpjm0,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]