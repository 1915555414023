import type { AppConfigInput } from '@nuxt/schema'

export default {
  breadcrumb: {
    slots: {
      link: 'text-xs font-medium',
      linkLeadingIcon: 'size-4',
    },
    variants: {
      active: {
        true: {
          link: 'text-(--black-primary) font-medium',
        },
      },
    },
  },
} as AppConfigInput['ui']
