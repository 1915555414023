export const currencyCookieName = 'vsf-currency'
export const countryCookieName = 'vsf-country'
export const localeCookieName = 'vsf-locale'
export const cartCookieName = 'vsf-cart'
export const customerCookieName = 'vsf-customer'
export const storeCookieName = 'vsf-store'
export const messageCookieName = 'vsf-message'
export const phpSessionIdCookieName = 'PHPSESSID'
export const loginRedirectCookieName = 'login_redirect'
export const loginEmailCookieName = 'login-email'
export const langRedirectCookieName = 'wp_lang_redirect_store_code'
