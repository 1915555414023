import cartFieldsFragment from './cartFields.fragment'

export default `
  mutation mergeCarts($sourceCartId: String!, $destinationCartId: String!) {
    mergeCarts(source_cart_id: $sourceCartId, destination_cart_id: $destinationCartId) {
      ${cartFieldsFragment}
    }
  }
  
`
