export default `
  id
  email
  total_quantity
  is_virtual
  applied_coupons {
    code
  }
  prices {
    subtotal_excluding_tax {
      value
    }
    subtotal_including_tax {
      value
    }
    applied_taxes {
      amount {
        value
      }
      label
    }
    discounts {
      amount {
        value
      }
      label
    }
    grand_total {
      value
    }
  }
  free_shipping {
    message
    display_mini_cart
    display_checkout_cart
  }
  items {
    uid
    product {
      uid
      id
      __typename
      sku
      name
      stock_status
      only_x_left_in_stock
      special_note
      rating_summary
      thumbnail {
        url
        position
        disabled
        label
      }
      url_key
      price_range {
        maximum_price {
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
      }
    }
    prices {
      row_total {
        value
      }
      row_total_including_tax {
        value
      }
      total_item_discount {
        value
      }
    }
    quantity
    ... on ConfigurableCartItem {
      configurable_options {
        configurable_product_option_uid
        option_label
        configurable_product_option_value_uid
        value_label
      }
      configured_variant {
        stock_status
        sku
        id
        thumbnail {
          url
        }
      }
      customizable_options {
        customizable_option_uid
        label
        type
        values {
          value
        }
      }
    }
    ... on SimpleCartItem {
      customizable_options {
        customizable_option_uid
        label
        type
        values {
          value
        }
      }
    }
    ... on BundleCartItem {
      bundle_options {
        uid
        label
        type
        values {
          id
          label
          price
          quantity
        }
      }
    }
  }
  shipping_addresses {
    selected_shipping_method {
      price_incl_tax {
        value
      }
    }
  }
`
