import _debounce from '~/utils/debounce'

interface CacheItemData {
  customer_ids: string
  attributes: Array<any>
}

interface CacheItem {
  url: string
  data: CacheItemData
  attributesLength: number
}

let cache: CacheItem[] = []
let cacheLoading = true
let cacheResult

const debounceAndConsolidate = _debounce(async () => {
  // Condsolidate attributes
  let attributes = []
  for (let item of cache) {
    attributes = [...attributes, ...(item.data?.attributes ?? [])]
  }

  const consolidated = cache[0]

  // Check if object exists
  if (!consolidated) {
    cache = []
    return
  }

  consolidated.data.attributes = attributes

  // Make the call
  cacheResult = await useExponeaApi().postConsolidated(consolidated.url, consolidated.data)
  cacheLoading = false

  // Reset caching variables
  cache = []
}, 500)

const cacheHandler = async (url, data) => {
  // Push call to cache
  cacheLoading = true
  const cachePositionStart = cache.length
    ? cache.map((item) => item.attributesLength).reduce((curr, prev) => curr + prev, 0)
    : 0
  const cachePositionEnd = cachePositionStart + data.attributes.length
  cache.push({ url, data, attributesLength: data.attributes.length })

  // Consolidate all requested items and make the call
  debounceAndConsolidate()

  // Wait until call is made
  while (cacheLoading) {
    await new Promise((resolve) => requestAnimationFrame(resolve))
  }

  // Get the requested data back and return it
  return cacheResult.slice(cachePositionStart, cachePositionEnd)
}

export const useExponeaApi = () => {
  const { $i18n } = useNuxtApp()
  const { locale } = $i18n
  const baseUrl = () => `/api/exponea/${locale.value}`

  const get = async (url: string) => {
    try {
      return $fetch(baseUrl() + url, {
        method: 'GET',
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const post = async (url: string, data) => {
    if (url === '/customers/attributes' && 'cookie' in data.customer_ids) {
      // This url tends to be called more often at once. So consolidate it.
      return cacheHandler(url, data)
    } else {
      return postConsolidated(url, data)
    }
  }

  const postConsolidated = async (url: string, data) => {
    try {
      const response = await $fetch(baseUrl() + url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      return response?.results
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return {
    get,
    post,
    postConsolidated,
  }
}

export default useExponeaApi
