import validate from "/builds/my-jewellery/code/frontend-nuxt3/node_modules/nuxt/dist/pages/runtime/validate.js";
import general_45middleware_45global from "/builds/my-jewellery/code/frontend-nuxt3/middleware/generalMiddleware.global.ts";
export const globalMiddleware = [
  validate,
  general_45middleware_45global
]
export const namedMiddleware = {
  bloomreach: () => import("/builds/my-jewellery/code/frontend-nuxt3/middleware/bloomreach.ts"),
  catalog: () => import("/builds/my-jewellery/code/frontend-nuxt3/middleware/catalog.ts"),
  utils: () => import("/builds/my-jewellery/code/frontend-nuxt3/middleware/utils.ts")
}