import customerFragment from '~/server/utils/magento-queries/customer.fragment'

export default `
  mutation changeCustomerPassword($currentPassword: String!, $newPassword: String!) {
    changeCustomerPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      customer {
        ${customerFragment}
      }
    }
  }
`
