export default `
  id
  firstname
  lastname
  middlename
  email
  date_of_birth
  addresses {
    id
    firstname
    lastname
    street
    city
    region {
      region_code
      region
    }
    postcode
    country_code
    telephone
    default_shipping
    default_billing
  }
`
