import { customerCookieName } from '~/enums/cookieNameEnum'
import magentoQueries, { type GetApiMethods, type PostApiMethods } from '~/server/utils/magento-queries'

export default defineNuxtPlugin(() => {
  const apiMethods = <Record<PostApiMethods | GetApiMethods, any>>{}

  const postApiMethod = async (key: PostApiMethods) =>
    (apiMethods[key] = async (variables: Record<string, any>) => {
      const customerCookie = useCookie(customerCookieName)

      const data: {
        method: 'POST'
        body: Record<string, any>
        headers: Record<string, string>
      } = {
        method: 'POST',
        body: variables,
        headers: {},
      }

      if (customerCookie?.value) data.headers['authorization'] = `Bearer ${customerCookie.value}`

      return $fetch(`/api/magento/v2/${key}`, data)
    })

  const getApiMethod = async (key: GetApiMethods) =>
    (apiMethods[key] = async (variables: Record<string, any>) => {
      let requestUrl = `/api/magento/v2/${key}`
      if (variables) {
        requestUrl += `?variables=${JSON.stringify(variables)}`
      }

      return $fetch(requestUrl)
    })

  Object.keys(magentoQueries.post).forEach((key) => postApiMethod(key))
  Object.keys(magentoQueries.get).forEach((key) => getApiMethod(key))

  return {
    provide: {
      magento: apiMethods,
    },
  }
})
