import type { Cart, CartItemInterface } from '@vue-storefront/magento-types'
import _merge from 'lodash.merge'
import type { ComputedRef, Ref } from 'vue'

export interface CartState {
  cart: Ref<Cart>
  cartLoading: Ref<boolean>
  cartLoaded: Ref<boolean>
  payment: Ref<{
    available_payment_methods: any[]
    adyen_payment_methods: any[]
  }>
  shipping: Ref<{
    shipping_options: any[]
    pickup_locations: any[]
    selected_shipping_method: Record<string, any> | null
    token: string
  }>
  showAtcModal: Ref<boolean>
  setCart: (cart: Cart) => void
  cartItems: ComputedRef<CartItemInterface[]>
}

export const useCartStore = defineStore('cart', (): CartState => {
  const state = reactive({
    cart: {
      id: null,
      is_virtual: false,
      total_quantity: 0,
      shipping_addresses: [
        {
          id: null,
          available_shipping_methods: [],
          city: '',
          company: null,
          country: {
            code: '',
            label: '',
          },
          customer_notes: null,
          firstname: '',
          lastname: '',
          pickup_location_code: null,
          postcode: null,
          region: null,
          selected_shipping_method: null,
          street: [''],
          telephone: '',
        },
      ],
      items: [],
      applied_coupons: null,
      billing_address: {
        id: '',
        city: '',
        company: null,
        country: {
          code: '',
          label: '',
        },
        firstname: '',
        lastname: '',
        postcode: null,
        region: null,
        street: [''],
        telephone: '',
      },
      prices: {
        subtotal_excluding_tax: null,
        subtotal_including_tax: null,
        grand_total: null,
        discounts: [],
      },
    },
    cartLoading: true,
    cartLoaded: false,
    payment: {
      available_payment_methods: [],
      adyen_payment_methods: [],
    },

    shipping: {
      shipping_options: [],
      pickup_locations: [],
      selected_shipping_method: null,
      token: '',
    },
    showAtcModal: false,
  })

  const setCart = (cartData: Cart) => {
    if (!cartData) return

    // Ensure reactivity on shipping_addresses
    if (!cartData.shipping_addresses) {
      cartData.shipping_addresses = []
    }

    const assignData = _merge(state.cart, cartData)

    // Don't persist these state.carts from _merge, but use the exact value from cartData
    assignData.items = cartData.items
    assignData.applied_coupons = cartData.applied_coupons
    assignData.prices.discounts = cartData.prices?.discounts

    state.cart = assignData
  }

  // Getters
  const cartItems: ComputedRef<CartItemInterface[]> = computed(() => state.cart.items)

  return {
    ...toRefs(state),
    setCart,
    cartItems,
  }
})

export default useCartStore
