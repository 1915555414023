export default `
  mutation createCustomerV2($input: CustomerCreateInput!) {
    createCustomerV2(input: $input) {
      customer {
        created_at
        email
        id
      }
    }
  }
`
