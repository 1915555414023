import {
  cartCookieName,
  customerCookieName,
  loginRedirectCookieName,
  phpSessionIdCookieName,
} from '~/enums/cookieNameEnum'
import type { RouteLocationNormalizedGeneric } from 'vue-router'

// Deduce if a pretty URL is being used, if so make sure the renderKey stays the same so the page doesn't re-render
const generateRenderKey = (to: RouteLocationNormalizedGeneric, from: RouteLocationNormalizedGeneric) => {
  if (to.path.includes('.html') && to.path.split('/').length < 4) {
    to.meta.key = to.path.replace('.html', '')
  } else if (to.path.includes('.html')) {
    const { prettyUrls } = storeToRefs(usePrettyUrlStore())

    const getEndPart = (path: string) => {
      return path
        .substring(path.lastIndexOf('/') + 1)
        .replace('.html', '')
        .split('-')
    }

    const getMatchedUrl = (endPart: string[]) => {
      return endPart.find((url) => prettyUrls.value.find((prettyUrl) => prettyUrl.slug === url))
    }

    const getRelativeUrl = (path: string) => {
      return path.substring(0, path.lastIndexOf('/'))
    }

    const toEndPart = getEndPart(to.path)
    const toMatchedPrettyUrl = getMatchedUrl(toEndPart)
    const toRelativeUrl = getRelativeUrl(to.path)
    const fromEndPart = getEndPart(from.path)
    const fromMatchedPrettyUrl = getMatchedUrl(fromEndPart)
    const fromRelativeUrl = getRelativeUrl(from.path)

    switch (true) {
      case toMatchedPrettyUrl && toRelativeUrl === fromRelativeUrl:
        to.meta.key = toRelativeUrl
        break

      case toMatchedPrettyUrl && toRelativeUrl === from.path.replace('.html', ''):
        to.meta.key = toRelativeUrl
        break

      case fromMatchedPrettyUrl && fromRelativeUrl === to.path.replace('.html', ''):
        to.meta.key = fromRelativeUrl
        break

      default:
        to.meta.key = to.path.replace('.html', '')
    }
  }
}

const syncVueWithMagento = async ({
  customerToken,
  phpSessionCookie,
  cartId,
  storeUrl,
  localePath,
  logger,
}): Promise<void> => {
  if ((customerToken.value && phpSessionCookie.value) || !cartId) return logger.info('[Magento Sync] Skipped')
  const url = `${storeUrl}${localePath('/')}/vue/cart/sync/token/${
    customerToken.value !== undefined ? customerToken.value : ''
  }/cart/${cartId}`

  logger.debug('[Magento Sync] URL: ', url)

  try {
    const response = await $fetch(url)
    if (!response.ok) {
      throw new Error(`status: ${response.status}`)
    }
    logger.debug('[Magento Sync] Done')
  } catch (error) {
    logger.error('[Magento Sync] Error:', error)
  }
}

const redirectCustomerPages = ({ customerPages, loginPages, customerToken, to }) => {
  // On customer pages: redirect to account if logged in, or login if not logged in
  if (customerPages.some((page) => to.path.includes(page))) {
    if (customerToken.value && loginPages.some((page) => to.path.includes(page))) {
      navigateTo('/customer/account')
      return true
    }
    if (!customerToken.value && !loginPages.some((page) => to.path.includes(page))) {
      navigateTo('/customer/account/login')
      return true
    }
  }
}

const abortRouteNavigation = ({ logger, to }) => {
  // Ignore unknown API routes for routing
  // IF route starts with /{locale}/api
  const regexPattern = /^\/([a-z]|-){2,5}\/api\//
  const knownApiRoutes = ['/api/exponea', '/api/bloomreach/page', '/api/bloomreach/pages', '/api/bloomreach/document']
  if (regexPattern.test(to.path) && !knownApiRoutes.some((el) => to.path.includes(el))) {
    logger.info(`Ignored API route: ${to.path}`)
    abortNavigation()
    return true
  }

  const ignoredRoutes = ['/vue/cart/sync', '/sw.js', '_nuxt/node_modules']
  if (ignoredRoutes.some((el) => to.path.includes(el))) {
    logger.info(`Ignored route: ${to.path}`)
    abortNavigation()
    return true
  }

  // Ignore routes with file extensions (except .html)
  if (!to.path.endsWith('.html') && /^.*\.[0-9a-z]+$/i.test(to.path)) {
    logger.info(`Ignored route: ${to.path}`)
    abortNavigation()
    return true
  }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const {
    public: { storeUrl, loginFlowEnabled, accountPageEnabled },
  } = useRuntimeConfig()
  const localePath = useLocalePath()
  const logger = useAppLogger('generalMiddleware.global.ts')

  if (to.path.includes('/01/')) return

  const locale = to.path.split(/[/-]/)[1]

  const logicLocales = Object.fromEntries(
    loginFlowEnabled.split(',').map((locale) => [locale.split('=')[0], locale.split('=')[1] === 'true']),
  )
  const loginFlowEnabledForCurrentLocale = logicLocales[locale]

  const accountPageLocales = Object.fromEntries(
    accountPageEnabled.split(',').map((locale) => [locale.split('=')[0], locale.split('=')[1] === 'true']),
  )
  const accountPageEnabledForCurrentLocale = accountPageLocales[locale]

  const customerToken = useCookie(customerCookieName)
  const cartCookie = useCookie(cartCookieName)
  const loginRedirect = useCookie(loginRedirectCookieName)
  const phpSessionCookie = useCookie(phpSessionIdCookieName)

  const cartId = cartCookie.value?.indexOf('|') ? cartCookie.value?.split('|').pop() : cartCookie.value

  const loginPages = [
    '/customer/account/login',
    '/customer/account/create',
    '/customer/account/forgotpassword',
    '/customer/account/createpassword',
  ]

  loginRedirect.value = loginPages.some((page) => from?.path?.includes(page))
    ? undefined
    : to.path.includes('/customer/account/login')
      ? window?.location.href
      : undefined

  // Temporary workaround for Magento pages (when not in dev mode), to ensure we leave the Nuxt app when navigating to these pages
  const magentoPages = ['/checkout']

  const customerPages = [
    '/customer/account',
    '/customer/address',
    '/customer/account/edit',
    '/sales/order',
    '/wishlist',
  ]

  if (!accountPageEnabledForCurrentLocale) {
    magentoPages.push(...customerPages)
  }

  const isMagentoPage = () => {
    if (loginFlowEnabledForCurrentLocale && loginPages.some((page) => to.path.includes(page))) {
      return false
    }
    return magentoPages.some((page) => to.path.includes(page))
  }

  const hasNoToken = !to.query.token

  if (isMagentoPage() && hasNoToken) {
    const config = useRuntimeConfig()
    const targetUrl = config.public.storeUrl + to.path

    logger.info(`Redirecting to non-Nuxt page: ${targetUrl}`)

    if (!import.meta.dev) {
      await syncVueWithMagento({ customerToken, phpSessionCookie, cartId, storeUrl, localePath, logger })
      return navigateTo(targetUrl, { external: true })
    }
  }

  if (redirectCustomerPages({ customerPages, loginPages, customerToken, to })) return

  if (abortRouteNavigation({ logger, to })) return

  generateRenderKey(to, from)
})
