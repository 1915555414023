import button from './config/button'
import input from './config/input'
import link from './config/link'
import drawer from './config/drawer'
import checkbox from './config/checkbox'
import alert from './config/alert'
import breadcrumb from './config/breadcrumb'
import separator from './config/separator'
import modal from './config/modal'

export default defineAppConfig({
  ui: {
    ...button,
    ...input,
    ...link,
    ...drawer,
    ...checkbox,
    ...alert,
    ...breadcrumb,
    ...separator,
    ...modal,
  },
})
