export const checkElement = async (selector: string, delay = 5000) => {
  const startTime = Date.now()
  while (document.querySelector(selector) === null) {
    if (Date.now() - startTime > delay) {
      throw new Error(`Element ${selector} not found after ${delay / 1000} seconds`)
    }
    await new Promise((resolve) => requestAnimationFrame(resolve))
  }
  return document.querySelector(selector)
}
