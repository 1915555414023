import type { Product } from '~/types/product'
import type { Attribute, AttributeOption } from './useProductStore'

import YoutubeVimeoUrlParser from '~/utils/YoutubeVimeoUrlParser'
import { getAttributeData } from './helpers/getAttributeData'

export const useProductStore = defineStore('product', () => {
  const logger = useAppLogger('Store/Bloomreach')
  const { transformImageUrlToSize, ImageSize } = useMagentoImage()

  const state = reactive({
    activeProductSku: <string | null>null,
    products: <{ [pid: number | string]: Product }>{},
    configurableOptions: <{ [pid: number]: any }>{},
    productCustomOptionsConfiguration: <any>{},
    attributes: <Attribute[]>[],
    giftcardHolder: <Product | null>null,
    media: <{ [pid: number]: [] }>{},
    loading: false,
  })

  const setProduct = async (product: Product) => {
    logger.info(`setProduct`, { name: product?.name })
    state.activeProductSku = product.sku || ''
    state.giftcardHolder = null

    product?.configurable_options?.sort((a, b) => a.position - b.position)

    state.products[product.sku] = product

    await setProductMedia()
    updateConfigurableOptions({})
  }

  const updateGiftcardConfiguration = (optionUid: string, variantUid: string) => {
    const attribute = state.products[state.activeProductSku]?.configurable_options.find(
      (option) => option.attribute_uid === optionUid,
    )
    if (attribute?.attribute_code !== 'giftcard_price') return

    const value = attribute.values.find((attribute) => attribute.uid === variantUid)
    const attributePrice = parseInt(value?.swatch_data?.value) || 0
    if (!attributePrice) return

    state.products[state.activeProductSku] = {
      ...state.products[state.activeProductSku],
      price_range: {
        ...state.products[state.activeProductSku]?.price_range,
        minimum_price: {
          regular_price: {
            ...state.products[state.activeProductSku]?.price_range.minimum_price.regular_price,
            value: attributePrice,
          },
          final_price: {
            ...state.products[state.activeProductSku]?.price_range.minimum_price.regular_price,
            value: attributePrice,
          },
        },
      },
    }
  }

  /**
   * Retrieves attributes data.
   * @returns A promise that resolves to void.
   * @throws If an error occurs while retrieving the attributes data.
   */
  const getAttributes = async (): Promise<void> => {
    try {
      state.attributes = await getAttributeData()
    } catch (err) {
      throw err
    }
  }

  /**
   * Retrieves the label of an attribute by its ID and type.
   * @param attributeId The ID of the attribute.
   * @param type The type of the attribute.
   * @returns The label of the attribute, or an empty string if not found.
   */
  const getAttributeById = (attributeId: string, type: string): string => {
    const attribute = state.attributes
      ?.find((attribute) => attribute.attribute_code === type)
      ?.attribute_options.find((option) => option.value === attributeId)
    return attribute?.label || ''
  }

  /**
   * Retrieves the attribute options for a given attribute string.
   * @param attributeString - The attribute string to retrieve options for.
   * @returns An array of AttributeOption objects or undefined if the attribute string is not found.
   */
  const getAttribute = (attributeString: string): AttributeOption[] | undefined => {
    const productAttributes = state.products[state.activeProductSku]?.[attributeString]?.split(',')
    if (!productAttributes) return
    return state.attributes
      ?.find((attribute) => attribute.attribute_code === attributeString)
      ?.attribute_options.filter((option) => productAttributes.includes(option.value))
  }

  const productCustomOptionsCanAddToCartHandler = (sku) => {
    if (!state.products[sku]?.options?.length) return true

    return state.products[sku]?.options?.every((option) => state.productCustomOptionsConfiguration?.[option.uid])
  }

  /**
   * Waits for the product to finish loading.
   * @returns A promise that resolves to true when the product is loaded.
   */
  const waitForProduct = async (sku: string) => {
    while (state.loading === true) {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }
    return true
  }

  /**
   * Sets the product media by filtering, sorting, and mapping the media gallery.
   * If a video is present, it normalizes the video URL, retrieves the video thumbnail,
   * and determines the video provider (either Vimeo or YouTube).
   * @returns {Promise<void>} A promise that resolves when the product media is set.
   */
  const setProductMedia = async (): Promise<void> => {
    try {
      state.media[state.activeProductSku] = await Promise.all(
        state.products[state.activeProductSku]?.media_gallery
          .filter((x) => !x.disabled)
          .sort((a, b) => a.position - b.position)
          // @ts-ignore - The type name and video_content should exist
          .map(async ({ url, label, video_content, position }) => {
            const asset = {
              url,
              alt: label,
              position,
            }

            if (video_content?.video_url) {
              const videoParser = new YoutubeVimeoUrlParser()
              // @ts-ignore - video should exist
              asset.video = {
                url: videoParser.getNormalizedSrc(video_content.video_url),
                title: video_content.video_title,
                provider: 'regular',
                id: videoParser.parseUrl(video_content.video_url).videoId,
                thumbnail: await videoParser.getVideoThumbnail(video_content.video_url),
              }

              if (video_content.video_url.includes('vimeo')) {
                // @ts-ignore
                asset.video.provider = 'vimeo'
              } else if (video_content.video_url.includes('youtube')) {
                // @ts-ignore
                asset.video.provider = 'youtube'
              }
            }
            return asset
          }),
      )
    } catch (error) {
      logger.error('useProductStore/setProductMedia', error)
    }
  }

  const filterProductMediaVariants = (variants, value) =>
    variants?.filter((variant) => variant.attributes?.find((attribute) => attribute.uid === value))

  /**
   * Updates the product media based on the selected product configuration.
   * It filters the variants based on the selected attributes and updates the media accordingly.
   * If no variants are found, the media remains unchanged.
   */
  const updateProductMedia = async (productConfiguration: Record<string, any>): Promise<Number> => {
    const activeProductSku = state.activeProductSku
    if (!activeProductSku) return 0
    const productMedia = state.media[activeProductSku]
    const originalMediaLength = state.products[activeProductSku]?.media_gallery.filter((item) => !item.disabled).length

    let variants: any = state.products[activeProductSku]?.variants

    Object.values(productConfiguration).forEach((value) => {
      if (!variants?.length || !value) return
      variants = filterProductMediaVariants(variants, value)
    })

    const COLOR_UID = 'Mjkw'

    if (!variants?.length || !productMedia || !productConfiguration[COLOR_UID]) {
      return 0
    }

    // First time we add the variant image, next time we only replace it
    const mediaArray = productMedia.length > originalMediaLength ? productMedia?.slice(1) : productMedia
    const variantImage = {
      alt: variants?.[0]?.product.thumbnail?.label,
      url: transformImageUrlToSize(variants?.[0]?.product.thumbnail?.url, ImageSize.Original),
    }

    // compare images, if they're the same, don't add.
    async function imageToDataURL(imageUrl: string) {
      const response = await fetch(imageUrl)
      const img = await response?.blob()
      const bitmap = await createImageBitmap(img)
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = 20
      canvas.height = 20
      const bitmapHalfWidth = bitmap.width / 2
      const bitmapHalfHeight = bitmap.height / 2
      ctx?.drawImage(bitmap, bitmapHalfWidth - 10, bitmapHalfHeight - 10, 20, 20, 0, 0, 20, 20)
      return canvas.toDataURL('image/png')
    }

    try {
      const variantImageBase64 = await imageToDataURL(transformImageUrlToSize(variants?.[0]?.product.thumbnail?.url))
      const mediaBase64 = await Promise.all(
        mediaArray.map(async (media) => await imageToDataURL(transformImageUrlToSize(media.url))),
      )
      const matchingIndex = mediaBase64.findIndex((url) => url === variantImageBase64)

      if (matchingIndex !== -1) {
        return matchingIndex
      }
    } catch (error) {
      logger.error(error)
    }

    state.media[activeProductSku] = [variantImage, ...mediaArray]
    return 0
  }

  const filterOptions = (options, value) =>
    options.filter((variant) => variant.attributes.find((attribute) => attribute.uid === value.uid))

  const filterConfigurableOptions = (options, selectedUid) =>
    options.filter((variant) => variant.attributes.find((attribute) => attribute.uid === selectedUid))

  /**
   * Updates the configurable options based on the current product configuration and stock status.
   */
  const updateConfigurableOptions = (productConfiguration: Record<string, any>) => {
    const productRef = state.products[state.activeProductSku]

    if (!productRef?.variants?.length) return

    const mapOption = (value, options) => {
      const variants = filterOptions(options, value)
      return {
        ...value,
        stock_indication: variants.length === 1 ? variants[0].product.stock_indication : null,
        out_of_stock: variants.every((variant) => variant.product.stock_status === 'OUT_OF_STOCK')
          ? 'OUT_OF_STOCK'
          : 'IN_STOCK',
      }
    }

    const mapConfigurableOptions = (option, index) => {
      let options = productRef.variants
      for (let i = 0; i <= index - 1; i++) {
        const selectedUid = productConfiguration?.[productRef.configurable_options[i]?.attribute_uid]
        if (!selectedUid) continue
        options = filterConfigurableOptions(options, selectedUid)
      }

      const values = option.values.map((value) => mapOption(value, options))

      return {
        ...option,
        uid: productConfiguration?.[option.attribute_uid],
        values,
      }
    }

    state.configurableOptions[state.activeProductSku] = productRef.configurable_options.map((option, index) =>
      mapConfigurableOptions(option, index),
    )
  }

  return {
    ...toRefs(state),
    waitForProduct,
    setProduct,
    updateGiftcardConfiguration,
    updateConfigurableOptions,
    updateProductMedia,
    getAttributes,
    getAttribute,
    getAttributeById,
    productCustomOptionsCanAddToCartHandler,
  }
})

export default useProductStore
