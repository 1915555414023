import type { AppConfigInput } from '@nuxt/schema'

export default {
  input: {
    slots: {
      root: 'w-full h-[3rem]',
      base: 'h-[3rem] rounded-(--ui-radius) text-size[16px]',
    },
    compoundVariants: [
      {
        color: 'primary',
        variant: 'outline',
        class: 'focus-visible:ring-1',
      },
    ],
    variants: {
      size: {
        xs: { base: 'text-[16px]' },
        sm: { base: 'text-[16px]' },
        md: { base: 'text-[16px]' },
        lg: { base: 'text-[16px]' },
        xl: { base: 'text-base' },
      },
    },
  },
} as AppConfigInput['ui']
